import React, {useState} from "react"
import type { HeadFC, PageProps } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FaArrowRight } from "react-icons/fa"

import Layout from "../components/layout"
import PageBanner from "../components/pagebanner"
import Seo from "../components/seo"
import ContactUsForm from "../components/forms/contactus"
import CaseStudy from "../components/homepage/casestudy"
import Quotes from "../components/homepage/quotes"
import Stats from "../components/homepage/stats"

const TeamPage: React.FC<PageProps> = () => {
  
  return (
    <Layout>
      <PageBanner><span className="colored">Our</span> Team</PageBanner>
      
      <div className="white-section">
        <div className="container">
          
          <div className="row">
            <div className="col-sm-6 col-md-4 col-md-offset-2 text-center item-block fh5co-heading">
              <div className="avatar"><StaticImage src="../images/1560450976157.jpg" alt="Robert Mahler" width={500} height={500} /></div>
              
              <blockquote>
                <h3>Robert Mahler</h3>
                <p className="skill">Marketing</p>
                
                <div className="bio">
                  <p>Rob has over 25 years in the IT field and in that time he has obatined his Microsoft Certified Systems Engineer certification, Cisco Certified Network Engineer certification and many more.</p>
                  <p>His passion for computers started at a very young age and has always been something that came naturally. He has owned his own computer businesses and has been employed as a Chief Technology Officer, Director of IT, and other IT-related positions. Helping people is what Rob does best. Rob currently lives in Sarasota, FL with his wife, where they enjoy the beauty of the area and spending quality time together.</p>
                </div>
              </blockquote>
            </div>
            
            <div className="col-sm-6 col-md-4 text-center item-block fh5co-heading">
              <div className="avatar"><StaticImage src="../images/1560450976158.jpg" alt="Harry Hillier" width={500} height={500} /></div>
              
              <blockquote>
                <h3>Harry Hillier</h3>
                <p className="skill">Development</p>
                
                <div className="bio">
                  <p>Harry has over a decade of experience building, deploying, and maintaining sites from simple business pages to complex web applications.</p>
                  <p>He got started with computers when his grandad gave him a CPC 6128 and never stopped learning about them. He still gets true excitement from learning about new design principles and ideas.</p>
                  <p>Currently living in Harwich, UK. The mainline ferry connection with the Netherlands.</p>
                </div>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TeamPage

const seo = {
  metaDesc: "PlexTech Marketing - Our Team",
}

export const Head = () => <Seo title="PlexTech Marketing" type="website" url="/team/" seo={seo} />
